$notifications-column-width: 510px;
$notifications-header-height: 71px;
$notifications-grey: #e5e5e5;
$notification-positive: #36cd8b;
$notification-negative: #d55240;
$notification-primary: #0af;

.notifications-bell {
    vertical-align: middle;
    &:before {
        font-size: 20px;
    }
}

/* Common Styles*/
section.notifications-left,
section.notifications-right {
    * {
        box-sizing: border-box;
    }
    .notification {
        font-size: 14px;
    }
    .archived {
        background-color: $notification-positive;
        .fa:before {
            content: '\f187';
        }
    }
    .updated {
        background-color: $notification-primary;
        .fa:before {
            content: '\f040';
        }
    }
    .deleted {
        background-color: $notification-negative;
        .fa:before {
            content: '\f1f8';
        }
    }
    .ready {
        background-color: $notification-positive;
        .fa:before {
            content: '\f1c6';
        }
    }
    .upload_request {
        background-color: $notification-positive;
        .fa:before {
            content: '\f093';
        }
    }
    .download_request {
        background-color: $notification-positive;
        .fa:before {
            content: '\f019';
        }
    }
    .upload {
        background-color: $notification-primary;
        .fa:before {
            content: '\f187';
        }
    }
    .notification-icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-left: 5px;

        font-size: 14px;
        line-height: 30px;
        color: #fff;
        text-align: center;

        border-radius: 50%;
    }
    .media {
        margin: 0;
        padding: 0;
        .media-left {
            display: table-cell;
            padding: 0 25px;

            vertical-align: middle;
        }
        .media-body {
            display: table-cell;

            vertical-align: middle;
        }
        .media-heading {
            margin-top: 0;
            margin-bottom: 5px;

            color: #767676;
            strong {
                color: #555;
            }
        }
    }
    .icon-close, .icon-group-close {
        float: right;
        padding: 10px 20px;

        font-family: initial;
        font-size: 18px;
        color: #b7b7b7;

        border: none;
        background: none;
        &.icon-group-close {
            padding-right: 38px;
            font-size: 22px;
        }
        &.icon-close {
            display: none;
        }
        &:hover {
            color: lighten(#b7b7b7, 10%);

            cursor: pointer;
        }
    }
    ul.notifications-category-list {
        padding: 0;

        list-style-type: none;
    }
}

aside.notifications-panel {
    position: fixed;
    top: 0;
    right: -$notifications-column-width;
    z-index: 999999;

    height: 100%;

    transition: all 0.5s ease-in-out;
    &.panel-expanded {
        right: 0;
    }
    section.notifications-left {
        position: absolute;
        top: $notifications-header-height;
        right: $notifications-column-width;

        float: left;
        width: 0;
        height: 0;
        ul.notifications-flyover {
            position: relative;
            margin-top: 0;
            margin-bottom: 0;
            li {
                display: none;
                &.notification {
                    position: relative;

                    display: block;
                    width: 450px;
                    height: 80px;
                    margin: 10px 0 0 10px;

                    font-size: 13px;

                    border: 1px solid $notifications-grey;
                    border-radius: 10px;
                    background-color: #fff;
                    box-shadow: 0 0 5px #f6f6f6;
                    .notification-creation-time {
                        display: none;
                    }
                    .media {
                        .media-heading {
                            margin: 0 20px 0 0;
                        }
                        .media-body {
                            width: 10000px;
                        }
                        .media-right {
                            display: table-cell;
                            max-height: 60px;
                            padding: 10px 0 0 0;
                            overflow: hidden;

                            vertical-align: top;

                            border-left: 1px solid $notifications-grey;
                            .media-label {
                                width: 100px;
                                margin: 0 0 10px;
                                padding: 0 10px;
                                overflow: hidden;

                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            .action-box {
                                text-align: center;
                                .media-label {
                                    color: #0af;
                                }
                            }
                            .close-box {
                                width: 100px;
                                padding: 10px 0 0 0;

                                color: #767676;

                                border: 0;
                                border-top: 1px solid $notifications-grey;
                                background-color: inherit;
                                .media-label {
                                    color: #a7aeb3;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.has-content {
            width: $notifications-column-width;
            height: auto;
            ul.notifications-flyover {
                width: $notifications-column-width;
            }
        }
    }
    section.notifications-right {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 99999;

        float: right;
        width: $notifications-column-width;
        height: 100%;
        margin: 0;

        border: 0;
        box-shadow: -1px 0 5px rgba(0, 0, 0, 0.1);
        .notifications-header {
            height: $notifications-header-height;
            padding: 25px 20px;
            -webkit-box-sizing: border-box;
               -moz-box-sizing: border-box;
                    box-sizing: border-box;

            font-size: 16px;
            font-weight: 600;
            color: #000;

            border-bottom: 1px solid #d2d2d2;
            border-left: 1px solid #ddd;
            background: #fff;

            .notifications-close {
                position: absolute;
                top: 15px;
                right: 15px;

                & > button:focus {
                    outline: none;
                }
            }
        }
        .notifications-list {
            top: 71px;
            bottom: 0;

            min-width: $notifications-column-width;
            height: calc(100% - #{$notifications-header-height});
            padding: 0;
            overflow: scroll;

            border-left: 1px solid #ddd;
            background-color: #f9f9f9;

            list-style: none;
            .notification {
                padding: 20px 20px 20px 0;

                font-size: 13px;

                background-color: #f0f0f0;
                &.read-notification-true {
                    background-color: #fff;
                }
                &:hover {
                    background-color: #f9f9f9;
                    .icon-close {
                        display: block;
                    }
                    cursor: pointer;
                }
                time.notification-creation-time {
                    color: #767676;
                }
                .notification-content {
                    margin: 0 0 10px;

                    color: #777;
                }
                .separator {
                    padding: 0 5px;

                    color: #767676;
                    font-weight: bold;
                }
                .action-label {
                    display: inline-block;
                    margin: 0;

                    color: #0af;
                    &:hover {
                        color: #42afe3;
                    }
                }
            }
            .category {
                height: 50px;
                padding-left: 20px;

                line-height: 50px;

                border-top: 1px solid $notifications-grey;
                border-bottom: 1px solid $notifications-grey;
                p {
                    margin: 0;

                    font-size: 13px;
                    color: #767676;
                }
            }
            .no-notifications {
                position: absolute;
                top: 33%;

                padding: 0 40px;

                color: #767676;
                text-align: center;

                list-style: none;
                .fa {
                    padding: 20px;

                    font-size: 55px;
                    color: #ccc;
                }
                span {
                    display: inline-block;
                    margin: 10px 80px;
                }
            }
            .divider {
                width: 100%;
                height: 1px;
                margin: 0;

                background: #f2f2f2;
            }
        }
    }
}

/* Notifications counter hidden by default*/
.notifications-nav span.badge {
    display: none;
}
